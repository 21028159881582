<script>
import { ALL_NOTES } from "./AllNotes.gql";
import NoteCard from "@/components/daybook/NoteCard.vue";
import VxDialogView from "@/components/vx/VxDialogView";
import SkeletonNoteCard from "@/components/daybook/SkeletonNoteCard";
import { injectActiveEmployee } from "@/mixins/employee";

export default {
  name: "AllNotesDialog",
  components: { NoteCard, SkeletonNoteCard, VxDialogView },
  mixins: [injectActiveEmployee],
  props: {},
  data: () => ({
    setLimit: 50,
    apolloLoading: false,
    notes: [],
    observer: null,
    after: null,
    hasNext: false,
    endCursor: null,
  }),
  computed: {},
  watch: {
    allNotes(v) {
      this.notes.push(...v);
    },
  },
  apollo: {
    allNotes: {
      query: ALL_NOTES,
      fetchPolicy: "cache-first",
      loadingKey: "apolloLoading",
      variables() {
        return {
          storeId: this.$route.params.storeId,
          after: this.after,
          first: 3,
        };
      },
      update(data) {
        try {
          this.observer.observe(this.$refs.bottom.$el);
        } finally {
          this.hasNext = data.allNotes.pageInfo.hasNextPage;
          this.hasPrev = data.allNotes.pageInfo.hasPreviousPage;
          this.startCursor = data.allNotes.pageInfo.startCursor;
          this.endCursor = data.allNotes.pageInfo.endCursor;
          var newNotes = data.allNotes.edges.map((edge) => edge.node);
          return newNotes;
        }
      },
    },
  },
  mounted() {
    try {
      this.observer = new IntersectionObserver(this.callback, {});
      this.observer.observe(this.$refs.bottom.$el);
    } finally {
      return;
    }
  },
  methods: {
    editNote(note) {
      if (!this.activeEmployeeCan.editNote) return false;

      this.selectedNote = note;
      this.dialog = true;
    },
    callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.hasNext) {
          this.after = this.endCursor;
        }
      });
    },
  },
};
</script>

<template>
  <VxDialogView
    ref="rootDialog"
    title="All notes"
    transition="dialog-bottom-transition"
  >
    <template v-if="!apolloLoading">
      <NoteCard
        v-for="note in notes"
        :key="note.id"
        :note="note"
        expanded
        @edit="editNote"
      />
      <v-container v-if="hasNext" fluid>
        <v-row class="justify-center">
          <v-progress-circular ref="bottom" indeterminate />
        </v-row>
      </v-container>
    </template>
    <SkeletonNoteCard v-for="i in 5" v-else :key="i" />
  </VxDialogView>
</template>
